import React from 'react';
import { useTranslation } from 'react-i18next';
import lang_icon from '../assets/img/lang_icon.png';
function LanguageToggle() {
  const { i18n , t } = useTranslation();

  const toggleLanguage = () => {
    // Check the current language and switch to the other one
    const newLang = i18n.language === 'en' ? 'ar' : 'en';
    i18n.changeLanguage(newLang);
  };

  return (
    <div className='header__language'>
      <button className='language_switcher' onClick={toggleLanguage}>
          <img src={lang_icon} alt="lang_icon" width={'50px'} />
      </button>
    </div>
  );
}

export default LanguageToggle;