// src/i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import en from '../locales/en.json';
import ar from '../locales/ar.json';

i18n
  .use(initReactI18next) // Pass i18n instance to react-i18next
  .init({
    resources: {
      en: { translation: en },
      ar: { translation: ar },
    },
    fallbackLng: 'en', // Default language if the detected language is unavailable
    interpolation: {
      escapeValue: false, // React already escapes values by default
    },
    detection: {
      // Configuration for language detection
      order: ['navigator', 'localStorage', 'cookie'], // Detect from browser navigator first, then localStorage, then cookies
      caches: ['localStorage', 'cookie'], // Cache the language in localStorage and cookie
    },
  });

export default i18n;
