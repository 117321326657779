import barcodeImg from '../assets/img/barcode.png';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import axios from 'axios';
import ErrorPage from './ErrorPage';
import { useTranslation } from 'react-i18next';
import LanguageToggle from '../components/LanguageToggle';
import Loader from '../components/Loader';
import SubDomainFinder from '../Hooks/SubDomainFinder';

export default function ProductDetails() {
    const navigate = useNavigate();
    const params = useParams();
    const [code , setCode] = useState(params.code);
    const [data , setData] = useState();
    const [error , setError] = useState(true);
    const { i18n , t } = useTranslation();
    const [loading, setLoading] = useState(true);
    const subDomain = SubDomainFinder();
    const apiDomain = 'https://'+ subDomain +'.'+process.env.REACT_APP_API_DOMAIN + '/price_checker/product/';
    const redirectDelay = parseInt(process.env.REACT_APP_AUTO_REDIRECT_DELAY || '10000', 10);

    useEffect(() => {
        setCode(params.code?params.code:'No data yet');
        axios(
            {
                method: 'get',
                url: apiDomain + params.code,
                headers: {
                    'Accept-Language': i18n.language,
                    'Accept': 'application/json',
                },
            }
        ).then((response) => {
            setError(false);
            setLoading(false);

            setData(response.data);
        }).catch((error) => {
            setError(true);
            setLoading(false);
        });
    }, [i18n.language]);
   
    useEffect(() => {
        // This useEffect replaces the current history entry with the home page
        const timer = setTimeout(() => {
            navigate('/');
        }, redirectDelay);

        // Clear the timer if the component unmounts to avoid memory leaks
        return () => clearTimeout(timer);
    }, [redirectDelay]);
    const scanBarcode = () => {
        navigate('/barcode');
    }

    return (
        loading ? <Loader /> : error?<ErrorPage/>:  
        <div className="container">
                                        <LanguageToggle />

                <div className='main-container pro-details'>
                    <div className="product">
                        <div className="pro-img">
                            <img src={data?.data.image} width={'100%'} alt="product" />
                        </div>
                        <div className="pro-text">
                            <p className="pro-english-name">
                                {data?.data.name}
                            </p>
                        </div>
                        <div className="pro-price">
                            <div className="pro-updated-price">
                                {data?.data.discounted_price}{data?.data.currency}
                            </div>
                            {data?.data.discount !== "0.00" ?<div className="pro-main-price">{data?.data.price}{data?.data.currency}</div>:null}
                        </div>

                    </div>
                    
                    <div className="home-footer" onClick={(e)=>scanBarcode()}>
                        <div className="footer-text">
                            <p>{t('Scan another')}, <span onClick={(e)=>scanBarcode()} className='start-scan-btn'>{t('Now')}</span></p>
                        </div>
                        <div className="footer-img">
                            <img src={barcodeImg} width={'75px'} height={'46px'} alt="barcodeImg" />

                        </div>

                    </div>
                </div> 

        </div>
    );
}