import React, { useEffect, useState } from 'react';
import Quagga from 'quagga';
import barcodeImg from '../assets/img/barcode-white.png';
import fram from '../assets/img/fram.png';
import logoImage from '../assets/img/logo.png';
import { useNavigate } from 'react-router-dom';
import LanguageToggle from '../components/LanguageToggle';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import SubDomainFinder from '../Hooks/SubDomainFinder';

export default function Barcode() {
    const [barcode, setBarcode] = useState('No data yet');
    const [isCameraActive, setIsCameraActive] = useState(true);
    const navigate = useNavigate();
    const [data, setData] = useState();
    const [loading, setLoading] = useState(true);
    const { i18n , t } = useTranslation(); // Initialize translation function
    const subDomain = SubDomainFinder();
    const apiDomain = 'https://'+ subDomain +'.'+process.env.REACT_APP_API_DOMAIN + '/price_checker/store/info';   
    
    const requestCameraPermission = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({
                video: {
                    facingMode: 'environment',
                    focusMode: 'continuous', // Enable continuous auto-focus
                }
            });
            if (stream) {
                stream.getTracks().forEach((track) => track.stop()); // Stop the stream once permission is granted
                setIsCameraActive(true);
            }
        } catch (error) {
            console.error('Camera permission denied', error);
            setIsCameraActive(false);
        }
    };

    useEffect(() => {
        axios(
            {
                method: 'get',
                url: apiDomain,
                headers: {
                    'Accept-Language': i18n.language,
                    'Accept': 'application/json',
                },
            }
        ).then((response) => {
            setLoading(false);
            setData(response.data);
            console.log(data.data);
        }).catch((error) => {
            console.log(error);
        });
        if (isCameraActive) {
            Quagga.init({
                inputStream: {
                    type: 'LiveStream',
                    constraints: {
                        facingMode: 'environment',
                        advanced: [{ focusMode: 'continuous' }] // Auto-focus configuration
                    },
                    target: document.querySelector('#barcode-scanner'),
                },
                decoder: {
                    readers: ['code_128_reader', 'ean_reader'],
                },
                locate: true, // Enable locate to improve scan speed
            }, (err) => {
                if (err) {
                    console.error(err);
                    return;
                }
                Quagga.start();
            });

            let lastDetectedCode = null;
            Quagga.onDetected((result) => {
                const code = result.codeResult.code;
                if (code !== lastDetectedCode) {
                    lastDetectedCode = code;
                    setBarcode(code);
                    navigate('/Product/' + code);
                }
            });

            // Cleanup on component unmount
            return () => {
                Quagga.stop();
            };
        } else {
            requestCameraPermission(); // Request permission if the camera is inactive
        }
    }, [isCameraActive , i18n.language]);

    return (
        <div className='container'>
            <LanguageToggle />

            <div className='main-container barcode-container'>
                <div className="logo barcod-scaner-text">
                    <div className="logo-img">
                        <img src={logoImage} width={'120px'} height={'72px'} alt="logo" />
                        <p><span>{t('Welcome')}</span>, {data?.data.name}!</p>
                        </div>
                    <div className="logo-text">
                        <p>{data?.data.welcome_sentence} </p>
                    </div>
                </div>
                <div id="barcode-scanner">
                    {/* <div className='fram'>
                        <img src={fram} width={'75px'} height={'46px'} alt="fram" />
                    </div> */}
                </div>
                <img src={barcodeImg} width={'75px'} height={'46px'} alt="barcodeImg" />
            </div>
        </div>
    );
}
