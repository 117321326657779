import '../css/main.css';
import Loader from '../components/Loader';
import { useEffect, useState } from 'react';
import barcodeImg from '../assets/img/barcode.png';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import LanguageToggle from '../components/LanguageToggle';
import SubDomainFinder from '../Hooks/SubDomainFinder';
import ErrorPage from './ErrorPage';
function Home() {
    
    const { t } = useTranslation(); // Initialize translation function
    const { i18n } = useTranslation();
    const [error , setError] = useState(false);
    const navigate = useNavigate();
    const scanBarcode = () => {
        navigate('/barcode');
    }
    const subDomain = SubDomainFinder();
    
    const apiDomain = 'https://'+ subDomain +'.'+process.env.REACT_APP_API_DOMAIN + '/price_checker/store/info';
    console.log(apiDomain);
    const [data , setData] = useState();
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        setLoading(true);
        axios(
            {
                method: 'get',
                url: apiDomain,
                headers: {
                    'Accept-Language': i18n.language,
                    'Accept': 'application/json',
                },
            }
        ).then((response) => {
            setLoading(false);
            setError(false);
            setData(response.data);
            console.log(data.data);
        }).catch((error) => {
            setLoading(false);
        });
    }, []);

    useEffect(() => {
        setLoading(true);
        axios(
            {
                method: 'get',
                url: apiDomain,
                headers: {
                    'Accept-Language': i18n.language,
                    'Accept': 'application/json',
                },
            }
        ).then((response) => {
            setError(false);

            setLoading(false);
            setData(response.data);

        }).catch((error) => {
            setLoading(false);
            setError(true);
        });
    }, [i18n.language]);
    
  return (
    loading ? <Loader /> : error ? <ErrorPage /> :
    <div className="container">
        <LanguageToggle />
        <div className='main-container'>

            <div className="logo">
                <div className="logo-img">
                    <img src={data?.data.logo} width={'120px'} height={'72px'} alt="logo" />
                    <p>
                        <span>{t('Welcome')}</span>, 
                        <span style={data?.data.color_a ? { color: data.data.color_a } : null}>
                            {data?.data.name}
                        </span>
                    </p>                
                </div>
                <div className="logo-text">
                    <p>
                        {data?.data.welcome_sentence} 
                    </p>
                </div>
            </div>
            <div className="home-footer" onClick={(e)=>scanBarcode()}>
                <div className="footer-text">
                    <p>{t('start scaning')}, <span  className='start-scan-btn' style={data?.data.color_b ? { color: data.data.color_b } : null}>{t('Now')}</span></p>
                </div>
                <div className="footer-img">
                    <img src={barcodeImg} width={'75px'} height={'46px'} alt="barcodeImg" />
                </div>
            </div>
        </div>
       
    </div>
  );
}

export default Home;
