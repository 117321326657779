export default function SubDomainFinder() {

    const {
        hostname
    } = window.location; // Get the full hostname
    const parts = hostname.split('.'); // Split by dots

    // Check if there are more than two parts (indicating a subdomain)
    if (parts.length > 2) {
        return parts.slice(0, -2).join('.'); // Join the subdomain parts
    }

    return ''; // No subdomain detected
}