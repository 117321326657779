
import rror404Img from '../assets/img/error404.png';
import barcodeImg from '../assets/img/barcode.png';
import { useNavigate } from 'react-router-dom';
import LanguageToggle from '../components/LanguageToggle';
import { useTranslation } from 'react-i18next';

  
export default function ErrorPage() {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const scanBarcode = () => {
        navigate('/barcode');
    }
    
    return (
        <div className="container">
            <LanguageToggle />
            <div className='main-container error-container'>
                <div className="error">
                    <div className="error-img">
                        <img src={rror404Img} width={'100%'} alt="logo" />
                    </div>
                
                </div>
                
                <div className="home-footer" onClick={(e)=>scanBarcode()}>
                        <div className="footer-text">
                            <p>{t('Scan another')}, <span onClick={(e)=>scanBarcode()} className='start-scan-btn'>{t('Now')}</span></p>
                        </div>
                        <div className="footer-img">
                            <img src={barcodeImg} width={'75px'} height={'46px'} alt="barcodeImg" />

                        </div>

                </div>
            </div>
        </div>
        );
    }